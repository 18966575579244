@import "../../../less/_variables/general.less";
@import "../../../less/_variables/typography.less";
@import "../../../less/oocss/fonts.less";
.link {
	.font-body-medium-bold();

	display: inline-flex;
	align-items: center;
	gap: var(--spacing-100);
	color: var(--color-link-default);
	text-decoration: underline;

	background: none;
	border: none;
	padding: 0;
	cursor: pointer;

	&:hover,
	&:focus {
		color: var(--color-link-default);
		text-decoration: underline;
	}

	&:focus-visible {
		outline: var(--color-link-blue) auto 2px;
		outline-offset: var(--spacing-100);
		border-radius: var(--border-radius-100);
	}

	&-icon {
		display: flex;
		align-items: center;
		color: inherit;
		width: 16px;
		height: 16px;
		font-size: 16px;
		text-decoration: none;
		float: left;

		& > * {
			color: inherit;
			width: 16px;
			height: 16px;
			font-size: 16px;
			text-decoration: none;
		}
	}

	&-blue {
		color: var(--color-link-blue);
		text-decoration: none;

		&:hover,
		&:focus {
			color: var(--color-link-blue);
			text-decoration: underline;
		}
	}

	&-weak {
		color: var(--color-link-weak);
		text-decoration: none;

		&:hover,
		&:focus {
			color: var(--color-link-weak);
			text-decoration: underline;
		}
	}
}
