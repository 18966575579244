@import "../../../node_modules/@is24/cosma-design-tokens/build/less/variables.colors.light.less";
@import "../../../node_modules/@is24/cosma-design-tokens/build/less/variables.colors.dark.less";
@import "../../../node_modules/@is24/cosma-design-tokens/build/less/variables.sizes.desktop.less";
@import "../../../node_modules/@is24/cosma-design-tokens/build/less/variables.sizes.palm.less";

:root {
	// COLOR VARIABLES
	.colors-light();

	[data-color-scheme="light"] {
		.colors-light();
	}

	[data-color-scheme="dark"] {
		.colors-dark();
	}

	// SIZE VARIABLES
	.sizes-palm();

	@media @mediaQueryLapAndUp {
		.sizes-desktop();
	}
}
