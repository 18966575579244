@import "../_variables/typography.less";

@import "../../../../node_modules/@is24/cosma-design-tokens/build/less/styles.text.less";

.font-heading-xlarge-bold {
	&:extend(.font-heading-xlarge-bold all);
	font-family: @fontFamilyStandard-theme-cosma;
	.heading-margin();
	.font-default-color();
}

.font-heading-xlarge-regular {
	&:extend(.font-heading-xlarge-regular all);
	font-family: @fontFamilyStandard-theme-cosma;
	.heading-margin();
	.font-default-color();
}

.font-heading-large-bold {
	&:extend(.font-heading-large-bold all);
	font-family: @fontFamilyStandard-theme-cosma;
	.heading-margin();
	.font-default-color();
}

.font-heading-large-regular {
	&:extend(.font-heading-large-regular all);
	font-family: @fontFamilyStandard-theme-cosma;
	.heading-margin();
	.font-default-color();
}

.font-heading-medium-bold {
	&:extend(.font-heading-medium-bold all);
	font-family: @fontFamilyStandard-theme-cosma;
	.heading-margin();
	.font-default-color();
}

.font-heading-medium-regular {
	&:extend(.font-heading-medium-regular all);
	font-family: @fontFamilyStandard-theme-cosma;
	.heading-margin();
	.font-default-color();
}

.font-heading-small-bold {
	&:extend(.font-heading-small-bold all);
	font-family: @fontFamilyStandard-theme-cosma;
	.heading-margin();
	.font-default-color();
}

.font-heading-small-regular {
	&:extend(.font-heading-small-regular all);
	font-family: @fontFamilyStandard-theme-cosma;
	.heading-margin();
	.font-default-color();
}

.font-heading-xsmall-bold {
	&:extend(.font-heading-xsmall-bold all);
	font-family: @fontFamilyStandard-theme-cosma;
	.heading-margin();
	.font-default-color();
}

.font-heading-xsmall-regular {
	&:extend(.font-heading-xsmall-regular all);
	font-family: @fontFamilyStandard-theme-cosma;
	.heading-margin();
	.font-default-color();
}

.font-body-large-bold {
	&:extend(.font-body-large-bold all);
	font-family: @fontFamilyStandard-theme-cosma;
	.font-default-color();
}

.font-body-large-regular {
	&:extend(.font-body-large-regular all);
	font-family: @fontFamilyStandard-theme-cosma;
	.font-default-color();
}

.font-body-medium-bold {
	&:extend(.font-body-medium-bold all);
	font-family: @fontFamilyStandard-theme-cosma;
	.font-default-color();
}

.font-body-medium-regular {
	&:extend(.font-body-medium-regular all);
	font-family: @fontFamilyStandard-theme-cosma;
	.font-default-color();
}

.font-body-small-bold {
	&:extend(.font-body-small-bold all);
	font-family: @fontFamilyStandard-theme-cosma;
	.font-default-color();
}

.font-body-small-regular {
	&:extend(.font-body-small-regular all);
	font-family: @fontFamilyStandard-theme-cosma;
	.font-default-color();
}

h1 {
	&:extend(.font-heading-xlarge-bold all);
	.heading-margin();
	.font-default-color();
}

h2 {
	&:extend(.font-heading-large-bold all);
	.heading-margin();
	.font-default-color();
}

h3 {
	&:extend(.font-heading-medium-bold all);
	.heading-margin();
	.font-default-color();
}

h4 {
	&:extend(.font-heading-small-bold all);
	.heading-margin();
	.font-default-color();
}

h5 {
	&:extend(.font-heading-xsmall-bold all);
	.heading-margin();
	.font-default-color();
}

.heading-margin {
	margin-bottom: 0.4em;
}

.font-default-color {
	color: var(--color-text-default);
}
