@import "../../../less/_mixins/typography.less";
@import "../../../less/_mixins/general.less";
@import "../../../less/_variables/general.less";


/* ---------- BUTTONS ---------- */
.button-rounded {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	border: none;
	cursor: pointer;
	font: inherit;
	outline: none;
	user-select: none;
	text-decoration: none;
	border-radius: 999px;

	font-family: @fontFamilyStandard-theme-cosma;
	font-weight: 700;
	gap: 8px;

	transition: all 300ms ease-in-out;

	&.button-rounded-loading {
		.label,
		.button-rounded-left-icon,
		.button-rounded-right-icon {
			visibility: hidden;
		}
	}
	.button-rounded-loader {
		position: absolute;
		z-index: 1;
	}

	.button-rounded-left-icon,
	.button-rounded-right-icon {
		display: inline-flex;
		align-items: center;
		justify-content: center;

		svg {
			width: 16px;
			height: 16px;
		}
	}

	/* ---------- SIZES ---------- */

	&.button-rounded-size-medium {
		height: 40px;
		padding: 14px 16px;
		font-size: 14px;
		line-height: 16px;
	}

	&.button-rounded-size-large {
		height: 48px;
		padding: 16px 24px;
		font-size: 16px;
		line-height: 16px;
	}

	/* ---------- WIDTH MODES ---------- */

	&.button-rounded-width-hug {
		width: fit-content;
	}

	&.button-rounded-width-fixed {
		width: 240px;

		.button-rounded-right-icon {
			position: absolute;
			right: 24px;
		}
	}

	/* ---------- VARIANTS ---------- */

	&.button-rounded-filled-default {
		background-color: var(--color-background-button-default-default);
		color: var(--color-text-button-inversive);

		&:hover:not(:disabled) {
			background-color: var(--color-background-button-default-hovered);
		}

		&:active:not(:disabled) {
			background-color: var(--color-background-button-default-pressed);
		}

		&:disabled {
			background-color: var(--color-background-button-disabled);
			color: var(--color-text-button-disabled);
			cursor: not-allowed;
		}
	}

	&.button-rounded-floating-default {
		background-color: var(--color-background-button-default-default);
		color: var(--color-text-button-inversive);
		box-shadow: 0 4px 12px 0 rgba(51, 51, 51, 0.50);

		&:hover:not(:disabled) {
			background-color: var(--color-background-button-default-hovered);
		}

		&:active:not(:disabled) {
			background-color: var(--color-background-button-default-pressed);
		}

		&:disabled {
			background-color: var(--color-background-button-disabled);
			color: var(--color-text-button-disabled);
			cursor: not-allowed;
		}
	}

	&.button-rounded-filled-weak {
		background-color: var(--color-background-button-weak-default);
		color: var(--color-text-button-default);

		&:hover:not(:disabled) {
			background-color: var(--color-background-button-weak-hovered);
		}

		&:active:not(:disabled) {
			background-color: var(--color-background-button-weak-pressed);
		}

		&:disabled {
			background-color: var(--color-background-button-disabled);
			color: var(--color-text-button-disabled);
			cursor: not-allowed;
		}
	}

	&.button-rounded-outline-strong {
		background-color: var(--color-background-button-inversive-default);
		border-color: var(--color-border-button-strong-default);
		color: var(--color-text-button-default);
		box-shadow: inset 0 0 0 1px var(--color-border-button-strong-default);

		&:hover:not(:disabled):not(:focus) {
			background-color: var(--color-background-button-inversive-hovered);
			box-shadow: inset 0 0 0 2px var(--color-border-button-strong-hovered);
			border-color: var(--color-border-button-strong-hovered);
		}

		&:active:not(:disabled) {
			box-shadow: inset 0 0 0 2px var(--color-border-button-strong-pressed);
			border-color: var(--color-border-button-strong-pressed);
		}

		&:disabled {
			box-shadow: inset 0 0 0 2px var(--color-border-button-disabled);
			border-color: var(--color-border-button-disabled);
			color: var(--color-border-button-disabled);
			cursor: not-allowed;
		}

		&:focus-visible {
			box-shadow: inset 0 0 0 2px var(--color-border-button-strong-active);
		}
	}

	&.button-rounded-text-default {
		color: var(--color-text-button-default);
		background-color: inherit;

		&:hover:not(:disabled) {
			background-color: var(--color-background-button-inversive-hovered);
		}

		&:active:not(:disabled) {
			background-color: var(--color-background-button-inversive-pressed);
		}

		&:disabled {
			color: var(--color-text-button-disabled);
			cursor: not-allowed;
		}
	}

	&.button-rounded-elevated-inversive {
		background-color: var(--color-background-button-inversive-default);
		color: var(--color-text-button-default);
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);

		&:hover:not(:disabled) {
			background-color: var(--color-background-button-inversive-hovered);
		}

		&:active:not(:disabled) {
			background-color: var(--color-background-button-inversive-pressed);
		}

		&:disabled {
			background-color: var(--color-background-button-disabled);
			color: var(--color-text-button-disabled);
			cursor: not-allowed;
		}
	}

	&.button-rounded-filled-brand {
		background-color: var(--color-background-button-brand-default);
		color: var(--color-text-button-charcoal);

		&:hover:not(:disabled) {
			background-color: var(--color-background-button-brand-hovered);
		}

		&:active:not(:disabled) {
			background-color: var(--color-background-button-brand-pressed);
		}

		&:disabled {
			background-color: var(--color-background-button-disabled);
			cursor: not-allowed;
			color: var(--color-text-button-disabled);
		}
	}

	&.button-rounded-outline-weak {
		background-color: transparent;
		border-color: var(--color-border-button-weak-default);
		color: var(--color-text-button-default);
		box-shadow: inset 0 0 0 1px var(--color-border-button-weak-default);

		&:hover:not(:disabled):not(:focus) {
			border-color: var(--color-border-button-weak-hovered);
			box-shadow: inset 0 0 0 2px var(--color-border-button-weak-hovered);
		}

		&:active:not(:disabled) {
			border-color: var(--color-border-button-weak-pressed);
			box-shadow: inset 0 0 0 2px var(--color-border-button-weak-pressed);
		}

		&:disabled {
			box-shadow: inset 0 0 0 2px var(--color-border-button-disabled);
			color: var(--color-border-button-disabled);
			border-color: var(--color-border-button-disabled);
			cursor: not-allowed;
		}

		&:focus-visible {
			box-shadow: inset 0 0 0 2px #1677ff;
		}
	}

	&.button-rounded-text-danger {
		background-color: var(--color-background-button-inversive-default);
		color: var(--color-text-button-danger);

		&:hover:not(:disabled) {
			background-color: var(--color-background-button-inversive-hovered);
		}

		&:active:not(:disabled) {
			background-color: var(--color-background-button-inversive-pressed);
		}

		&:disabled {
			background-color: var(--color-background-button-inversive-default);
			color: var(--color-text-button-disabled);
			cursor: not-allowed;
		}
	}

	&.button-rounded-filled-danger {
		background-color: var(--color-background-button-danger-strong-default);
		color: var(--color-text-button-inversive);

		&:hover:not(:disabled) {
			background-color: var(--color-background-button-danger-strong-hovered);
		}

		&:active:not(:disabled) {
			background-color: var(--color-background-button-danger-strong-pressed);
		}

		&:disabled {
			background-color: var(--color-background-button-disabled);
			color: var(--color-text-button-disabled);
			cursor: not-allowed;
		}
	}

	&.button-rounded-filled-danger-weak {
		background-color: var(--color-background-button-danger-weak-default);
		color: var(--color-text-button-danger);

		&:hover:not(:disabled) {
			background-color: var(--color-background-button-danger-weak-hovered);
		}

		&:active:not(:disabled) {
			background-color: var(--color-background-button-danger-weak-pressed);
		}

		&:disabled {
			color: var(--color-text-button-disabled);
			background-color: var(--color-background-button-disabled);
			cursor: not-allowed;
		}
	}

	&.button-rounded-filled-success-weak {
		background-color: var(--color-background-button-success-weak-default);
		color: var(--color-text-button-success);

		&:hover:not(:disabled) {
			background-color: var(--color-background-button-success-weak-hovered);
		}

		&:active:not(:disabled) {
			background-color: var(--color-background-button-success-weak-pressed);
		}

		&:disabled {
			color: var(--color-text-button-disabled);
			background-color: var(--color-background-button-disabled);
			cursor: not-allowed;
		}
	}

	&:focus-visible {
		box-shadow: inset 0 0 0 2px #1677ff;
	}
}
